import styles from "../Splash.module.css";
import CarousalCard from "../components/CarousalCard/CarousalCard";
import Carousal1 from "../Carousal_1.svg";
import Carousal2 from "../Carousal_2.svg";
import Carousal3 from "../Carousal_3.svg";
import Carousal4 from "../Carousal_4.svg";
import CarouselComponent from "../components/CarousalComponent/CarousalComponent";
import LoginMain from "./LoginMain";

// called in App.js -(it receives three props : apphandler, tokenhandler, credentialidhandler)
function Login(props) {

  
  const slidesArray = [
    <CarousalCard
      svgImage={Carousal1}
      title={"Your Digital Clinic"}
      description={
        "Manage your clinic easily from anywhere in the world using our plaform."
      }
    />,
    <CarousalCard
      svgImage={Carousal2}
      title={"Electronic Health Records"}
      description={
        "Seamless and secure Digital Health Records for Streamlined Care."
      }
    />,
    <CarousalCard
      svgImage={Carousal3}
      title={"Patient-Centric Consultations"}
      description={
        "Seamless Doctor-Patient Interaction with easy access to all historical data."
      }
    />,
    <CarousalCard
      svgImage={Carousal4}
      title={"Streamlined Nurse Intake"}
      description={
        "Easy Patient Onboarding for a better Experience: From Check-in to Care."
      }
    />,
  ];

  return (
    <>
      {/* This is the main container of ui elements which contains all the sub objects 
          and applies styles defined in the styles.main class.*/}
      <div className={styles.main}>
        <div className={styles.left}>
          <div className={styles.carousal}>
            <CarouselComponent slides={slidesArray} />
          </div>
        </div>
        <div className={styles.right}>
        <div className={styles.major}>
        <LoginMain/>
        </div>
        </div>
      </div>
     
    </>
  );
}

export default Login;
